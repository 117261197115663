import React from 'react';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  themeBackgorundImageStorageKey,
} from 'constants/defaultValues';
import GlideComponent from 'components/carousel/GlideComponent';
import { ThemeColors } from 'helpers/ThemeColors';
// import Cookies from 'js-cookie';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const setCurrentBackgroundImg = (img) => {
  try {
    localStorage.setItem(themeBackgorundImageStorageKey, img);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentimg -> error', error);
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = async () => {
  let user = null;

  const token = localStorage.getItem('jwt');
  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/current_user`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );

      if (response.ok) {
        user = await response.json();
      } else {
        user = null;
      }
    } catch (error) {
      console.log(
        '>>>>: src/helpers/Utils.js  : getCurrentUser -> error',
        error
      );
      user = null;
    }
  }
  return {
    id: 4,
    role: 0,
    user,
  };
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      // Cookies.set('name', 'value', { expires: 7 });
      localStorage.setItem('gogo_current_user', JSON.stringify(user));
      localStorage.setItem('jwt', user.user.token);
    } else {
      localStorage.removeItem('gogo_current_user');
      localStorage.removeItem('jwt');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getPerfilUser = async (blogId) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASEURL}/admin/profile?${process.env.REACT_APP_USERTOKEN}&blogId=${blogId}&${process.env.REACT_APP_USERID}&${process.env.REACT_APP_USUARIO}`
  );
  if (response.ok) {
    return response.json();
  }
  throw new Error(`Error al obtener el perfil: ${response.status}`);
};

export const getRedIcon = (network) => {
  switch (network) {
    case 'facebook':
      return 'fab fa-facebook-f';
    case 'instagram':
      return 'fab fa-instagram';
    case 'twitter':
      return 'fab fa-x-twitter';
    case 'linkedin':
      return 'fab fa-linkedin';
    case 'pinterest':
      return 'fab fa-pinterest';
    case 'youtube':
      return 'fab fa-youtube';
    case 'tiktok':
      return 'fab fa-tiktok';
    case 'googleads':
      return 'fab fa-google';
    case 'gmb':
      return 'fa-sharp fa-light fa-store';
    case 'facebookads':
      return 'fab fa-facebook-f';
    case 'web':
      return 'fas fa-globe';
    case 'tiktokads':
      return 'fab fa-tiktok';
    case 'twitch':
      return 'fab fa-twitch';
    default:
      return null;
  }
};

export const getRedColorHex = (network) => {
  switch (network) {
    case 'facebook':
      return '#3b5998';
    case 'instagram':
      return '#c13584';
    case 'twitter':
      return '#000000';
    case 'linkedin':
      return '#0077b5';
    case 'pinterest':
      return '#bd081c';
    case 'youtube':
      return '#ff0000';
    case 'tiktok':
      return '#000000';
    case 'googleads':
      return '#4285f4';
    case 'gmb':
      return '#34a853';
    case 'facebookads':
      return '#3b5998';
    case 'web':
      return '#000000';
    case 'tiktokads':
      return '#000000';
    case 'twitch':
      return '#6441a5';
    default:
      return null;
  }
};

export const getType = (schedule) => {
  switch (true) {
    case !!schedule.facebookData:
      return schedule.facebookData.type;
    case !!schedule.instagramData:
      return schedule.instagramData.type;
    case !!schedule.gmbData:
      return schedule.gmbData.type;
    case !!schedule.twitterData:
      return schedule.twitterData.type;
    case !!schedule.youtubeData:
      return schedule.youtubeData.type;
    case !!schedule.tiktokData:
      return schedule.tiktokData.type;
    case !!schedule.webData:
      return schedule.webData.type;
    case !!schedule.googleAdsData:
      return schedule.googleAdsData.type;
    case !!schedule.tiktokAdsData:
      return schedule.tiktokAdsData.type;
    case !!schedule.twitchData:
      return schedule.twitchData.type;
    case !!schedule.pinterestData:
      return schedule.pinterestData.type;
    case !!schedule.facebookAdsData:
      return schedule.facebookAdsData.type;
    default:
      return null;
  }
};

export const getBadgeColor = (type) => {
  const colors = ThemeColors();
  switch (type) {
    case 'POST':
      return colors.graficaColorPost;
    case 'REEL':
      return colors.graficaColorReel;
    case 'STORY':
      return colors.graficaColorStories;
    case 'Post':
      return colors.graficaColorPost;
    case 'Reels':
      return colors.graficaColorReel;
    case 'Story':
      return colors.graficaColorStories;
    default:
      return colors.graficaColorPost;
  }
};

const slideSettings = {
  type: 'carousel',
  gap: 15,
  perView: 1,
  hideNav: false,
  peek: { before: 10, after: 10 },
  // breakpoints: {
  //   600: { perView: 1 },
  //   992: { perView: 2 },
  //   1200: { perView: 3 },
  // },
};

const buildImageOrVideo = (mediaItem, index, w, h, controls) => {
  if (mediaItem.includes('mp4')) {
    return (
      <video
        key={`${mediaItem}-${index}`}
        width={w || '100%'}
        height={h || '100%'}
        src={mediaItem}
        alt={`${mediaItem}-${index}`}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          borderRadius: '5px',
        }}
        controls={controls}
      >
        <track kind="captions" srcLang="es" label="video" />
      </video>
    );
  }

  return (
    <img
      key={`${mediaItem}-${index}`}
      src={mediaItem}
      alt={`${mediaItem}-${index}`}
      width={w || '100%'}
      height={h || '100%'}
      style={{
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '5px',
      }}
    />
  );
};

export const imgageOrVide = (media, w, h, controls = true, carrusel = true) => {
  if (!media) {
    return <div>No media found</div>;
  }

  if (Array.isArray(media)) {
    return carrusel && media.length > 1 ? (
      <GlideComponent settings={slideSettings}>
        {media.map((mediaItem, index) =>
          buildImageOrVideo(mediaItem, index, w, h, controls)
        )}
      </GlideComponent>
    ) : (
      buildImageOrVideo(media[0], 0, w, h, controls)
    );
  }

  return buildImageOrVideo(media, 0, w, h, controls);
};
